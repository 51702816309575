import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/container";
import { PlainPageTitle, PlainPageHeader } from "../components/common";

const GDPR = () => (
  <Layout>
    <SEO
      title="GDPR"
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <Container>
      <PlainPageTitle>GDPR</PlainPageTitle>
      <PlainPageHeader>Overview</PlainPageHeader>

      <p>
        <b>
          <i>Percept AI is fully GDPR compliant.</i>
        </b>
      </p>

      <p>
        At Percept AI, we understand the importance of protecting the security
        and privacy of customer data, and we are committed to partnering with
        our clients to help them understand and prepare for the General Data
        Protection Regulation (GDPR). The GDPR is the most comprehensive EU data
        privacy law in decades, and will go into effect on May 25, 2018.
      </p>

      <p>
        Under the GDPR guidelines, our customer is the&nbsp;
        <b>Data Controller</b>, and Percept AI is a&nbsp;
        <b>Data Processor</b>. This means the customer will determine
        the purposes and means of processing Personal Data, while we as the Data
        Processor processes data on behalf of the Data Controller.
      </p>

      <p>
        <b>Personal Data</b>&nbsp;in the context of the GDPR includes
        any information which can identify an end user such as their name, email
        address, postal address, username and IP address. Depending on the use
        case, a subset of these information will be stored in or transmitted via
        the Percept AI services, by, or on behalf of, our customers and their
        end-users.
      </p>

      <div>
        Here we list some of our main efforts in pursuance of GDPR compliance:
        <ul>
          <li>Reviewed data security measures</li>
          <li>Added public instructions on how to exercise GDPR rights</li>
          <li>Audited third-party sub-processors</li>
          <li>
            Created standard DPAs (Data Processing Agreement) for future clients
          </li>
          <li>Appointed DPO (Data Protection Officer) per GDPR requirements</li>
        </ul>
      </div>

      <PlainPageHeader>Data Security</PlainPageHeader>

      <p>
        At Percept AI, our engineering team has invested heavily into our
        security systems to make sure our customers’ data are protected with
        high security standards by utilizing the state-of-art application and
        system security techniques.
      </p>

      <p>
        All communication with our service is performed through a secure
        connection. We do not provide any non-SSL endpoints. Data encryption is
        applied wherever possible which means that even in transit between our
        servers, your data is kept encrypted. All our servers are firewalled and
        kept updated with the latest security patches. All security keys and
        passwords stored by our application on your behalf are kept encrypted at
        rest.
      </p>

      <p>
        We also work with independent third-party security firms to run regular
        security reviews and penetration tests. The latest review results will
        be shared with our customers upon request.
      </p>

      <PlainPageHeader>Exercise GDPR Rights</PlainPageHeader>

      <p>
        Under GDPR, EU data subjects are entitled to exercise the rights listed
        below. Here the data subjects include both our customers (also known as
        “business users”) and their end users. For business users, the request
        must be sent from the same email as the account owner. For end users,
        the user must provide identification information that matches with the
        Personal Data collected in our system.
      </p>

      <p>
        We respond to requests within 30 days. However, it may take longer to
        complete the request. We’ll be sure to let you know these details over
        email. We use any information you give us in your request only to
        fulfill the request and delete it within 12 months.
      </p>

      <h4>Right of Access</h4>

      <p>
        All data subjects can request full access to their user&apos;s data by
        contacting privacy@percept.ai. For end users, this is limited to their
        own user profile and all the interactions that they have had with the
        Percept AI system.
      </p>

      <h4>Right of Rectification</h4>

      <p>
        For business users, most of their data can be viewed and edited directly
        through our console page. For information that are not available in
        console, please contact us to request modification.
      </p>

      <h4>Right to Erasure (“Right to be forgotten”)</h4>

      <p>
        All data subjects can request to have their personal data deleted by
        contacting privacy@percept.ai. For end users, this is limited to their
        own user profile and only the interactions that they have had with the
        Percept AI system.
      </p>

      <h4>Right to Restriction of Processing</h4>

      <p>
        All data subjects can ask for their personal data to stop being used in
        certain cases. Simply contact us at privacy@percept.ai and we will
        process your request.
      </p>

      <h4>Right to Data Portability</h4>

      <p>
        Upon request, we provide full export of a data subject’s account data in
        machine readable format. Please send the request to privacy@percept.ai.
      </p>

      <h4>Right to Object</h4>

      <p>
        Similar to Right to Erasure, we handle all requests on this matter from
        all our customers and their end users. Simply contact us at
        privacy@percept.ai and we will process your request.
      </p>

      <PlainPageHeader>Sub-processors</PlainPageHeader>

      <p>
        Percept AI uses sub-processors to assist in providing our Service. A
        sub-processor is a third party data processor engaged by Percept AI, who
        has or potentially will have access to or process service data (which
        may contain personal data). Percept AI evaluates the security, privacy
        and confidentiality practices of proposed sub-processors that have
        access to or process service data both before they are engaged and on an
        ongoing basis.
      </p>

      <p>
        The following is an up-to-date list (as of July 2018) of the names and
        locations of Percept AI sub-processors:
      </p>

      <div>
        <div>Amazon Web services</div>
        <div>Purpose: Hosting</div>
        <div>Location: United States</div>
        <div>Website: https://aws.amazon.com/</div>
        <br />
      </div>

      <div>
        <div>Papertrail</div>
        <div>Purpose: Log management</div>
        <div>Location: United States</div>
        <div>Website: papertrailapp.com/</div>
      </div>

      <PlainPageHeader>Data Processing Agreement (DPA)</PlainPageHeader>

      <p>
        We provide standard DPAs for our customers, which documents our
        responsibilities as a Data Processor and our approach to collecting,
        processing and storing Personal Data. If you are a customer who needs a
        signed DPA, please send an email to&nbsp;
        <a href="mailto:privacy@percept.ai">privacy@percept.ai</a>&nbsp;using
        the same email address as the account owner. Here is an example of our
        DPA.
      </p>

      <PlainPageHeader>Data Protection Officer (DPO)</PlainPageHeader>

      <p>
        We have appointed a DPO that can be reached at&nbsp;
        <a href="mailto:dpo@percept.ai">dpo@percept.ai</a>&nbsp;should you have
        any further questions regarding our data protection policy.
      </p>
    </Container>
  </Layout>
);

export default GDPR;
